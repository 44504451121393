export function getUser (state) {
  return {
    user: state.user,
    isAuthenticated: state.isAuthenticated
  }
}

export function getRecentlyViewed (state) {
  return state.recentlyViewed
}

export function getSharedCookbooks (state) {
  if (state.cookbooks && state.cookbooks.shared_cookbooks) {
    return state.cookbooks.shared_cookbooks
  }

  return []
}

export function getContributeCookbooks (state) {
  if (state.cookbooks && state.cookbooks.contribute_cookbooks) {
    return state.cookbooks.contribute_cookbooks
  }

  return []
}

export function getMyCookbooks (state) {
  return state.cookbooks
}

export function getFollowingCookbooks (state) {
  if (state.cookbooks && state.cookbooks.following_cookbooks) {
    return state.cookbooks.following_cookbooks
  }
  return []
}
