<template>
  <v-list
    style="background-color: #ececec; font-family: Lato;"
    class="body-1 font-weight-medium"
    dense
  >
    <template v-for="(item, i) in links">
      <v-list-item
        v-if="item.to"
        :id="`${item.pathName}`"
        :key="i"
        :to="item.to"
      >
        <div
          v-if="item.icon"
          class="pl-4"
        >
          <div
            v-if="item.defaultIcon"
            class="d-flex justify-center align-center"
            style="height:20px; width:20px; border-radius: 4px;"
            :style="`background-color:${item.color}`"
          >
            <component
              :is="item.defaultIcon"
            />
          </div>

          <component
            :is="item.inactive"
            v-else-if="item.inactive"
          />

          <v-icon
            v-else
            class="ml-n1"
            style="color:#2B2B2B;"
          >
            {{ item.active }}
          </v-icon>
        </div>

        <span
          class="text-truncate px-4"
          style="color:#2B2B2B;"
        > {{ item.title }}
        </span>
      </v-list-item>

      <v-list-item
        v-else-if="item.onClick"
        :id="`${item.pathName}`"
        :key="`large-menu-${i}`"
        :class="item.class"
        @click="$emit('onClick', item.onClick)"
      >
        <v-icon
          left
          style="color:#2B2B2B;"
          class="ml-n1 mr-4"
        >
          {{ item.icon }}
        </v-icon>
        <span style="color:#2B2B2B;">
          {{ item.title }}
        </span>
      </v-list-item>
    </template>

    <template v-for="(item, i) in cookbooksMenu">
      <v-list-item
        v-if="item.to"
        :id="`${item.pathName}`"
        :key="`__cb_${i}`"
        :to="item.to"
      >
        <v-img
          height="21"
          max-width="21"
          style="border-radius: 4px"
          :src="item.img"
          :lazy-src="getPlaceHolderImage"
          class="ml-4"
        />
        <span
          v-if="item.img"
        />
        <span
          class="text-truncate px-4"
          style="color:#2B2B2B;"
        > {{ item.title }}
        </span>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HomeActiveIcon from '@/assets/icons/home-active.svg'
import HomeInactiveIcon from '@/assets/icons/home-inactive.svg'
import ExplorerActiveIcon from '@/assets/icons/explorer-active.svg'
import ExplorerInactiveIcon from '@/assets/icons/explorer-inactive.svg'
import KitchenActive from '@/assets/icons/kitchen-active.svg'
import KitchenInactive from '@/assets/icons/kitchen-inactive.svg'
import HeartSvg from '@/assets/icons/heart-white.svg'
export default {
  components: { HomeActiveIcon, HomeInactiveIcon, ExplorerActiveIcon, ExplorerInactiveIcon, KitchenActive, KitchenInactive, HeartSvg },
  data () {
    return {
      active: this.$route.name,
      links: [
      // {
      //   to: '/',
      //   pathName: 'home',
      //   title: 'Home',
      //   icon: true,
      //   inactive: 'HomeInactiveIcon',
      //   active: 'HomeActiveIcon'
      // },
        {
          to: '/my-kitchen',
          pathName: 'myKitchen',
          title: 'My Kitchen',
          icon: true,
          inactive: 'KitchenInactive',
          active: 'KitchenActive',
          divider: true
        },
        {
          to: '/explorer',
          pathName: 'explorer',
          title: 'Explorer',
          icon: true,
          inactive: 'ExplorerInactiveIcon',
          active: 'ExplorerActiveIcon'
        },
        {
          to: '/create-recipe',
          pathName: 'create-recipe',
          title: 'Create Recipe',
          icon: true,
          active: 'mdi-plus-circle',
          class: 'mt-3'
        },
        {
          to: '/create-cookbook',
          onClick: 'openCreateCookbook',
          title: 'Create Cookbook',
          icon: true,
          active: 'mdi-plus-circle',
          class: 'mt-3'
        },
        {
          to: '/favorites',
          pathName: 'favorites',
          title: 'Favorites',
          icon: true,
          defaultIcon: 'HeartSvg',
          color: '#E86850'
        },
        {
          to: '/shared-with-me',
          pathName: 'shared-with-me',
          title: 'Shared with me',
          icon: true,
          defaultIcon: 'HeartSvg',
          color: '#587498'
        }
      // {
      //   to: '/recent',
      //   title: 'Recently Viewed',
      //   icon: null,
      //   img: 'https://static01.nyt.com/images/2015/07/27/dining/27SPAGHETTI/27SPAGHETTI-master675.jpg'
      // }
      ],
      cookbooksMenu: []
    }
  },
  computed: {
    ...mapState(['cookbooks']),
    getPlaceHolderImage () {
      return require('@/assets/cookbook_placeholder.png')
    }
  },
  watch: {
    cookbooks: function () {
      const newArry = []

      for (const item of this.cookbooks) {
        newArry.push({
          to: {
            name: 'cookbook',
            params: {
              id: item.id
            }
          },
          title: `${item.name}`,
          icon: null,
          img: item.image ? `${item.image}` : this.getPlaceHolderImage
        })
      }
      this.$set(this, 'cookbooksMenu', newArry)
    }
  },
  async created () {
    this.getCollaboratingCookbooks()
    await this.getCookbooks()
  },
  methods: {
    ...mapActions(['getCookbooks', 'getCollaboratingCookbooks']),
    isActive (path) {
      if (this.$route.name) {
        return this.$route.name === path
      }
      // default home route
      return false
    }
  }
}
</script>

<style lang="scss" scoped></style>
