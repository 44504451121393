<template>
  <v-row
    no-gutters
    style="max-width:940px;"
    :class="getClasses"
  >
    <v-col
      v-if="status === 'PENDING'"
      class="text-center mt-12"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>

    <v-col v-else-if="status === 'SUCCESS'">
      <slot />
    </v-col>

    <v-col
      v-else
      class="mt-8 text-center"
    >
      Something went wrong. Please try refreshing the page.
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: () => 'PENDING'
    }
  },
  computed: {
    getClasses () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          'pa-2': true,
          'mb-4': true
        }
      }
      return {
        'ml-14': true,
        'pa-2': true,
        'mb-4': true
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
