<template>
  <v-app style="background-color: #f3f3f3">
    <div
      v-if="
        !$user.isAuthenticated &&
          ($route.path === '/home' || $route.path === '/')
      "
    >
      <home-view
        @login="loginDialog = true"
        @signUp="signupDialog = true"
      />
    </div>
    <div
      v-else
      style="height: 100%"
    >
      <!-- <tour-view /> -->
      <nav
        v-if="!$route.meta.printView"
        class="px-8 elevation-1"
        style="
          background-color: white;
          height: 60px;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 10;
        "
      >
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%; height: 100%"
        >
          <div class="d-flex mr-2 align-center">
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndDown && $user.isAuthenticated"
              class="mr-2"
              @click="drawer = !drawer"
            />

            <div class="hidden-xs-only">
              <router-link
                to="/"
                style="text-decoration: none"
                class="d-flex align-center"
              >
                <div style="height: 42px; width: 42px">
                  <v-img :src="getZestipyImage" />
                </div>
                <div
                  class="black--text ml-2"
                  style="font-family: Nyata FTR; font-size: 32px"
                >
                  Zestipy
                </div>
              </router-link>
            </div>
          </div>
          <div
            v-if="$user.isAuthenticated"
            class="flex-grow-1"
          >
            <search-bar
              id="search"
              :class="$vuetify.breakpoint.mdAndDown ? 'mx-2' : 'mr-2'"
              :style="{
                width: $vuetify.breakpoint.mdAndUp ? '700px' : 'auto',
                marginLeft: $vuetify.breakpoint.lg ? '150px' : 'auto',
              }"
            />
          </div>

          <div>
            <div
              v-if="$user.isAuthenticated"
              class="d-flex align-center"
            >
              <v-menu
                bottom
                left
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <div
                    class="grey lighten-2 mr-6 d-flex align-center justify-center"
                    style="height: 42px; width: 42px; border-radius: 50%"
                  >
                    <div>
                      <v-img
                        v-bind="attrs"
                        style="cursor: pointer"
                        width="24px"
                        height="24px"
                        src="./assets/icons/noti.png"
                        v-on="on"
                      />
                    </div>
                  </div>
                </template>

                <v-card
                  height="400"
                  width="460"
                >
                  <div class="subtitle-2 pa-2">
                    Notifications
                  </div>
                  <notification-section />
                </v-card>
              </v-menu>

              <v-menu
                bottom
                left
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-avatar
                    left
                    size="42"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      :src="
                        $user?.user?.avatar
                          ? `${$user.user.avatar}`
                          : getAvatarPlaceHolder
                      "
                    />
                  </v-avatar>
                </template>

                <v-list width="200">
                  <v-list-item
                    v-for="item in avatarMenu"
                    :key="item.id"
                    :to="item.to"
                    :target="item.target"
                  >
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="handleLogout">
                    <v-list-item-title>Sign out</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div v-else>
              <v-btn
                rounded
                class="mx-2"
                @click="loginDialog = true"
              >
                Log in
              </v-btn>
              <v-btn
                rounded
                @click="signupDialog = true"
              >
                Sign up
              </v-btn>
            </div>
          </div>
        </div>
      </nav>

      <div
        v-if="$user.isAuthenticated"
        style="height: 100%"
      >
        <v-navigation-drawer
          v-if="$route.name !== 'recipe-print'"
          v-model="drawer"
          app
          style="background-color: #ececec; top: 55px"
          width="280"
        >
          <navigation-menu @onClick="handleClick" />
        </v-navigation-drawer>

        <v-main
          style="
            background-color: #f3f3f3;
            position: relative;
            top: 55px;
            height: 100%;
          "
        >
          <router-view />
        </v-main>
        <share-dialog />
        <report-dialog />
        <add-to-cookbook-dialog />
        <!-- toast -->
        <v-snackbar
          v-model="snackbar.status"
          color="2B2B2B"
          timeout="1500"
        >
          {{ snackbar.text }}

          <template #action="{ attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              @click="snackbar.status = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <div
        v-else
        style="height: 100%"
      >
        <!-- Other public pages i.e - terms/privacy/about -->
        <v-main
          style="
            background-color: #f3f3f3;
            position: relative;
            top: 55px;
            height: 100%;
          "
        >
          <router-view />
        </v-main>
      </div>
    </div>

    <login-dialog
      v-model="loginDialog"
      @openSignup="signupDialog = true; loginDialog = false"
      @close="loginDialog = false"
    />

    <signup-dialog
      v-model="signupDialog"
      @openLogin="loginDialog = true; signupDialog = false"
      @close="signupDialog = false"
    />

    <v-snackbar
      v-model="openInApp"
      :timeout="-1"
      height="65"
      @click="openAppStore"
    >
      <div class="d-flex">
        <v-img
          max-width="25"
          max-height="25"
          :src="getZestipyImage"
        />
        <a
          :href="getMobileAppUrl"
          target="_blank"
          class="mx-2 body-1 white--text font-weight-medium"
        >Open in Zestipy Mobile App</a>
      </div>

      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="openInApp = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { ANDROID, IOS } from './constants/links'

import HomeView from '@/views/PublicViews/HomeView.vue'
import NavigationMenu from './components/NavigationMenu.vue'

import LoginDialog from './views/LoginDialog.vue'
import SignupDialog from './views/SignupDialog.vue'
import ShareDialog from './views/ShareDialog.vue'
import ReportDialog from './views/ReportDialog.vue'
import AddToCookbookDialog from './views/AddToCookbookDialog.vue'
import TourView from './views/TourView.vue'
import SearchBar from './components/SearchBar.vue'

import { mapActions } from 'vuex'
import NotificationSection from './views/NotificationSection.vue'
import { EventBus } from '@/main'

export default {
  name: 'App',
  components: {
    NavigationMenu,
    LoginDialog,
    SignupDialog,
    ShareDialog,
    ReportDialog,
    AddToCookbookDialog,
    SearchBar,
    HomeView,
    TourView,
    NotificationSection
  },
  data: () => ({
    drawer: null,
    createRecipe: false,
    createCookbook: false,
    loginDialog: false,
    signupDialog: false,
    shareDialog: false,
    openInApp: false,
    avatarMenu: [
      {
        id: 1,
        to: '/account',
        text: 'My Account'
      },
      {
        id: 2,
        to: '/privacy-policy',
        target: '_blank',
        text: 'Privacy Policy'
      },
      {
        id: 3,
        target: '_blank',
        to: '/terms-of-use',
        text: 'Terms of use'
      }
    ],
    snackbar: {
      status: false,
      text: ''
    }
  }),
  computed: {
    getZestipyImage () {
      return require('@/assets/logo_blue_background.png')
    },
    getAvatarPlaceHolder () {
      return require('@/assets/profile_placeholder.png')
    },
    getMobileAppUrl () {
      const userAgent = navigator.userAgent
      if (/android/i.test(userAgent)) {
        return ANDROID
      }
      if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return IOS
      }
      return IOS
    }
  },
  created () {
    const isAuthenticated = window.localStorage.getItem('accessKey')
    if (isAuthenticated) {
      this.$store.dispatch('login', { credentials: null, tokenExists: true })
    }

    if (this.$vuetify.breakpoint.xsOnly) {
      this.openInApp = true
    }

    EventBus.$on('snackbar', (message) => {
      this.snackbar.text = message
      this.snackbar.status = true
    })
  },
  methods: {
    ...mapActions(['logout']),
    handleLogout () {
      this.logout()
        .then(() => {
          this.$router.push('/')
        })
        .then(() => {
          window.location.reload()
        })
    },
    handleClick (type) {
      if (type === 'openCreateRecipe') {
        this.createRecipe = true
      } else {
        this.createCookbook = true
      }
    },
    openAppStore () {
      window.location.href = this.getMobileAppUrl
    }
  }
}
</script>

<style scoped scss>
@font-face {
  font-family: 'Suez One';
  src: local('Suez One'), url(@/assets/SuezOne-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nyata FTR';
  src: local('Nyata FTR'), url(@/assets/NyataFTR-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Archivo Black';
  src: local('Archivo Black'),
    url(@/assets/ArchivoBlack-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(@/assets/Montserrat.ttf) format('truetype');
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer:hover {
  cursor: pointer;
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
>>> .v-navigation-drawer__border {
  width: 0px;
}
</style>
