<template>
  <v-footer
    color="primary lighten-1 body-2"
    padless
    style="position: absolute; bottom: 0; width: 100%; min-height: 5vh;"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.id"
        color="white"
        text
        rounded
        class="my-2 caption"
        :to="link.to"
        target="_blank"
      >
        {{ link.title }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data () {
    return {
      links: [
        {
          id: 1,
          title: 'Privacy Policy',
          to: '/privacy-policy'
        },
        {
          id: 2,
          title: 'Terms of Use',
          to: '/terms-of-use'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
