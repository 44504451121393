const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PublicViews/PrivacyView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '@/views/PublicViews/TermsOfUseView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/PublicViews/AboutView.vue')
  },
  {
    path: '/explorer',
    name: 'explorer',
    component: () => import(/* webpackChunkName: "explorer" */ '@/views/ExplorerView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/search/:keyword',
    name: 'search',
    component: () => import(/* webpackChunkName: "search-results" */ '@/views/SearchView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cookbook/:id',
    name: 'cookbook',
    component: () => import(/* webpackChunkName: "coobook" */ '@/views/CookbookView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cookbook/:id/edit',
    name: 'cookbook-edit',
    component: () => import(/* webpackChunkName: "coobook" */ '@/views/CookbookEditView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/recipe/:id',
    name: 'recipe',
    component: () => import(/* webpackChunkName: "recipe" */ '@/views/RecipeView.vue')
  },
  {
    path: '/recipe/:id/edit',
    name: 'recipe-edit',
    component: () => import(/* webpackChunkName: "recipe-edit" */ '@/views/RecipeEditView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/recipe/:id/cookingview',
    name: 'recipe-print',
    component: () => import(/* webpackChunkName: "recipe-open-cooking" */ '@/views/RecipeOpenCookingView.vue'),
    meta: { requiresAuth: true, printView: true }
  },
  {
    path: '/create-recipe',
    name: 'create-recipe',
    component: () => import(/* webpackChunkName: "create-recipe" */ '@/views/CreateRecipePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/create-cookbook',
    name: 'create-cookbook',
    component: () => import(/* webpackChunkName: "create-cookbook" */ '@/views/CreateCookbookPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/UserView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/my-kitchen',
    name: 'myKitchen',
    component: () => import(/* webpackChunkName: "my-kitchen" */ '@/views/MyKitchen.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shared-with-me',
    name: 'sharedWithMe',
    component: () => import(/* webpackChunkName: "shared-with-me" */ '@/views/SharedWithMeView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/recent',
    name: 'recent',
    component: () => import(/* webpackChunkName: "recently-viewed" */ '@/views/RecentlyViewedPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import(/* webpackChunkName: "fav" */ '@/views/FavouritesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "accounts" */ '@/views/AccountView.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/demo',
  //   name: 'account',
  //   component: () => import(/* webpackChunkName: "demo */ '@/views/DemoView.vue')
  // },
  // and finally the default route, when none of the above matches:
  { path: '*', component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/PageNotFound.vue') }
]
export default routes
