/* eslint-disable no-useless-catch */
import $axios from '@/plugins/axios'
import { API_PENDING, API_SUCCESS, API_FAIL } from '@/constants/api'

export async function signupWithEmail ({ commit }, newuser) {
  commit('setAuthLoading', true)
  try {
    const result = await $axios.post('/register', newuser)
    commit('setUser', result)
    commit('setAuthStatus', true)
    commit('setAuthLoading', false)
  } catch (error) {
    commit('setAuthStatus', false)
    commit('setAuthLoading', false)
    throw Error(error.data.message)
  }
}

export async function login ({ commit }, { credentials, tokenExists = false }) {
  commit('setAuthLoading', true)
  if (!tokenExists) {
    try {
      const { email, password } = credentials
      const result = await $axios.post('/login', {
        email,
        password
      })
      commit('setUser', result)
      commit('setAuthStatus', true)
      commit('setAuthLoading', false)
    } catch (error) {
      commit('setAuthStatus', false)
      commit('setAuthLoading', false)
      throw Error(error.data.message)
    }
  } else {
    commit('setUser', null)
    commit('setAuthStatus', true)
    commit('setAuthLoading', false)
  }
}

export async function logout ({ commit }) {
  try {
    await $axios.delete('/logout')
    window.localStorage.removeItem('accessKey')
    commit('setAuthStatus', false)
    commit('setAuthLoading', false)
  } catch (error) {
    window.localStorage.removeItem('accessKey')
    commit('setAuthStatus', false)
    commit('setAuthLoading', false)
    throw Error(error)
  }
}

export async function userValidate ({ commit }, payload) {
  try {
    return await $axios.post('/validate', payload)
  } catch (error) {
    throw error.data.message
  }
}

export async function passwordResetLink ({ commit }, email) {
  try {
    const response = await $axios.post('/password/email', { email })
    return response.message
  } catch (error) {
    throw error.data.message
  }
}

export async function forgetPassword ({ commit }, password) {
  try {
    return await $axios.post('/password/restore', password)
  } catch (error) {
    console.log(error)
  }
}

export async function updateUserProfile ({ commit }, payload) {
  try {
    const result = await $axios.put('/profile/update', payload)
    // commit('setUser', result)
  } catch (error) {
    throw Error(error)
  }
}

export async function getRecentlyViewed ({ commit }, pageNumber = 1) {
  try {
    const { data, links, meta } = await $axios.get('/recipes/recentlyViewed')
    commit('setRecentlyViewed', data)
    commit('updateMeta', { type: 'recentlyViewed', meta })
    commit('updateApiStatus', {
      type: 'recentlyViewed',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'recentlyViewed',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

export async function getFavouriteRecipes ({ commit }, pageNumber = 1) {
  try {
    const { data, links, meta } = await $axios.get(
      `/recipes/liked?page=${pageNumber}`
    )
    commit('setFavouriteRecipes', { data, pageNumber })
    commit('updateMeta', { type: 'favouriteRecipes', meta })
    commit('updateApiStatus', {
      type: 'favouriteRecipes',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'favouriteRecipes',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// shared with me
export async function getSharedRecipes ({ commit }, pageNumber = 1) {
  try {
    const { data, links, meta } = await $axios.get(
      `/recipes/shared?page=${pageNumber}`
    )
    commit('setSharedRecipes', { data, pageNumber })
    commit('updateMeta', { type: 'sharedWithMe', meta })
    commit('updateApiStatus', {
      type: 'sharedRecipes',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'sharedRecipes',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

export async function getSharedCookbooks ({ commit }, payload) {
  try {
    const result = await $axios.get('/cookbooks/shared')
    commit('setSharedCookbooks', result)
    commit('updateApiStatus', {
      type: 'sharedCookBooks',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'sharedCookBooks',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// my-profile
export async function getMyProfile ({ commit }) {
  try {
    const result = await $axios.get('/profile')
    const { id, name, username, email, avatar } = result.data
    commit('setUser', { id, name, username, email, avatar })
    commit('setMyProfile', result.data)
    commit('updateApiStatus', {
      type: 'myProfile',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'myProfile',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// notifications
export async function getMyNotification ({ commit }, id) {
  commit('updateApiStatus', { type: 'myNotifications', status: API_PENDING })
  try {
    const result = await $axios.get('/notifications')
    commit('setMyNotification', result)
    commit('updateApiStatus', { type: 'myNotifications', status: API_SUCCESS })
  } catch (error) {
    commit('updateApiStatus', { type: 'myNotifications', status: API_FAIL })
    throw Error(error)
  }
}

export async function readNotification ({ commit }, uid) {
  try {
    return await $axios.patch(`/notifications/${uid}`)
  } catch (error) {
    throw Error(error)
  }
}

// feed
export async function getHomeFeed ({ commit }, pageNumber = 1) {
  try {
    const { data, links, meta } = await $axios.get(
      `/users/feed?page=${pageNumber}`
    )
    commit('setHomeFeed', data)
    commit('updateMeta', { type: 'feed', meta })
    commit('updateApiStatus', {
      type: 'feed',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'feed',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// cookbooks
export async function getCookbooks ({ commit }, payload) {
  try {
    const result = await $axios.get('/cookbooks')
    commit('setCookbooks', result.data)
  } catch (error) {
    throw Error(error)
  }
}

export async function getFollowingCookbooks ({ commit }, payload) {
  try {
    const result = await $axios.get('/cookbooks/following')
    commit('setFollowingCookbooks', result)
  } catch (error) {
    throw Error(error)
  }
}

export async function getCollaboratingCookbooks ({ commit }, payload) {
  try {
    const result = await $axios.get('/cookbooks/collaborating')
    commit('setCollaboratingCookbooks', result.data)
  } catch (error) {
    throw Error(error)
  }
}

// Fetch By ID
export async function getRecipeById ({ commit }, { id, isLoggedIn = false }) {
  try {
    let result = null
    if (isLoggedIn) {
      result = await $axios.get(`/recipes/${id}`)
    } else {
      result = await $axios.get(`/recipes/${id}/share`)
    }
    return result.data
  } catch (error) {
    throw Error(error)
  }
}

export async function getCookbookById ({ commit }, id) {
  try {
    const result = await $axios.get(`/cookbooks/${id}`)
    return result.data
  } catch (error) {
    throw Error(error)
  }
}

export async function getUserById ({ commit }, id) {
  try {
    const result = await $axios.get(`profile/${id}/friend`)
    return result.data
  } catch (error) {
    throw Error(error)
  }
}

export async function getMyRecipes ({ commit }, pageNumber = 1) {
  try {
    const { data, meta } = await $axios.get(
      `/recipes/list/60?page=${pageNumber}`
    )
    commit('setMyRecipes', { data, pageNumber })
    commit('updateMeta', { type: 'myRecipes', meta })
    commit('updateApiStatus', {
      type: 'myRecipes',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'myRecipes',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// explorer - trending section
export async function getSuggestedRecipes ({ commit }, pageNumber = 1) {
  try {
    const { data, links, meta } = await $axios.get(
      `/recipes/suggested/30?page=${pageNumber}`
    )
    commit('setSuggestedRecipes', data)
    commit('updateMeta', { type: 'suggestedRecipes', meta })
    commit('updateApiStatus', {
      type: 'suggestedRecipes',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'suggestedRecipes',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

// CREATE
export async function createRecipe ({ commit }, payload) {
  try {
    const response = await $axios.post('/recipes', payload)
    return response.data
  } catch (error) {
    throw Error(error)
  }
}

export async function createCookbook ({ commit }, payload) {
  try {
    const response = await $axios.post('/cookbooks', payload)
    return response.data
  } catch (error) {
    throw Error(error)
  }
}

export async function createReview ({ commit }, { id, review }) {
  try {
    await $axios.post(`/recipes/${id}/review/add`, review)
  } catch (error) {
    throw Error(error)
  }
}

// EDIT
export async function updateRecipe ({ commit }, { id, recipe }) {
  try {
    const response = await $axios.put(`/recipes/${id}`, recipe)
    return response.data
  } catch (error) {
    throw Error(error)
  }
}

export async function updateCookbook ({ commit }, { id, cookbook }) {
  try {
    const response = await $axios.put(`/cookbooks/${id}`, cookbook)
    return response.data
  } catch (error) {
    throw Error(error)
  }
}

export async function updateProfile ({ commit }, payload) {
  try {
    await $axios.put('/profile/update', payload)
  } catch (error) {
    throw Error(error)
  }
}

export async function updateReview ({ commit }, { id, review }) {
  try {
    await $axios.put(`/recipes/review/${id}/update`, review)
  } catch (error) {
    throw Error(error)
  }
}

// DELETE
export async function clearReview ({ commit }, id) {
  try {
    await $axios.delete(`/recipes/review/${id}/remove`)
  } catch (error) {
    throw Error(error)
  }
}

// DELETE
export async function deleteRecipe ({ commit }, id) {
  try {
    await $axios.delete(`/recipes/${id}`)
  } catch (error) {
    throw Error(error)
  }
}

export async function deleteCookbook ({ commit }, id) {
  try {
    await $axios.delete(`/cookbooks/${id}`)
  } catch (error) {
    throw Error(error)
  }
}

export async function deleteReview ({ commit }, id) {
  try {
    await $axios.delete(`/recipes/review/${id}/remove`)
  } catch (error) {
    throw Error(error)
  }
}

export async function deleteMyAccount ({ commit }, id) {
  try {
    await $axios.delete('/')
  } catch (error) {
    throw Error(error)
  }
}

export async function followCookbook ({ commit }, id) {
  try {
    return await $axios.post(`/cookbooks/${id}/follow`)
  } catch (error) {
    throw Error(error)
  }
}

export async function unfollowCookbook ({ commit }, id) {
  try {
    return await $axios.post(`/cookbooks/${id}/unfollow`)
  } catch (error) {
    throw Error(error)
  }
}
export async function followUser ({ commit }, id) {
  try {
    await $axios.post(`/users/${id}/follow`)
  } catch (error) {
    throw Error(error)
  }
}
export async function unfollowUser ({ commit }, id) {
  try {
    await $axios.post(`/users/${id}/unfollow`)
  } catch (error) {
    throw Error(error)
  }
}
//
export async function addRecipeToCookbook (
  { commit },
  { recipeId, cookbookId }
) {
  try {
    await $axios.post(`/recipes/${recipeId}/cookbook/${cookbookId}/add`)
  } catch (error) {
    throw Error(error.data.message)
  }
}
// remove from cookbook
export async function removeFromCookbook ({ commit }, { recipeId, cookbookId }) {
  try {
    await $axios.delete(`/recipes/${recipeId}/cookbook/${cookbookId}`)
  } catch (error) {
    throw Error(error.data.message)
  }
}
export async function addRecipeToFavorites ({ commit }, recipeId) {
  try {
    await $axios.post(`/recipes/${recipeId}/like`)
  } catch (error) {
    throw Error(error)
  }
}

export async function removeFromFavorites ({ commit }, { recipeId }) {
  try {
    await $axios.post(`/recipes/${recipeId}/like`)
  } catch (error) {
    throw Error(error)
  }
}

// report
export async function reportReview ({ commit }, payload) {
  try {
    await $axios.post('/recipes/review/1/report')
  } catch (error) {
    throw Error(error)
  }
}

export async function reportCookbook ({ commit }, { id, type, description }) {
  try {
    await $axios.post(`/cookbooks/${id}/report`, { type, description })
  } catch (error) {
    throw Error(error)
  }
}

export async function reportRecipe ({ commit }, { id, type, description }) {
  try {
    await $axios.post(`/recipes/${id}/report`, { type, description })
  } catch (error) {
    throw Error(error)
  }
}

export async function reportUser ({ commit }, { id, type, description }) {
  try {
    await $axios.post(`/profile/${id}/report`, { type, description })
  } catch (error) {
    throw Error(error)
  }
}
// share
export async function shareRecipe ({ commit }, { recipeId, followerId }) {
  try {
    await $axios.post(`/recipes/share/${recipeId}`, { follower_id: followerId })
  } catch (error) {
    throw error.status
  }
}

export async function shareCookbook ({ commit }, { cookbookId, followerId }) {
  try {
    await $axios.post(`/cookbooks/share/${cookbookId}`, {
      follower_id: followerId
    })
  } catch (error) {
    throw Error(error)
  }
}

export async function getFollowers ({ commit }, id) {
  try {
    const result = await $axios.get(`/users/${id}/followers`)
    commit('setMyFollowers', result.data)
    commit('updateApiStatus', {
      type: 'myProfileFollowers',
      status: { isLoading: false, isLoaded: true }
    })
  } catch (error) {
    commit('updateApiStatus', {
      type: 'myProfileFollowers',
      status: { isLoading: false, isLoaded: false }
    })
    throw Error(error)
  }
}

export async function uploadImage ({ commit }, payload) {
  try {
    return await $axios.post('/images/upload/', payload)
  } catch (error) {
    throw Error(error)
  }
}

// Search - explorer
export async function explorerSuggest ({ commit }, payload) {
  try {
    return await $axios.post('/explorer/suggest', {
      query: payload
    })
  } catch (error) {
    throw Error(error)
  }
}
// my recipes search
export async function explorerSearch ({ commit }, payload) {
  try {
    const list = payload.split(' ')
    return await $axios.post('/recipes/search', {
      query: list
    })
  } catch (error) {
    throw Error(error)
  }
}
// explorer search
export async function explorerSearchAll ({ commit }, payload) {
  try {
    const list = payload.split(' ')
    return await $axios.post('/explorer/search', {
      query: list
    })
  } catch (error) {
    throw Error(error)
  }
}

export async function addNote ({ commit }, { id, note }) {
  try {
    return await $axios.post(`/recipes/${id}/notes`, {
      text: note
    })
  } catch (error) {
    throw Error(error)
  }
}

export async function updateNote ({ commit }, { id, note }) {
  try {
    return await $axios.put(`/recipes/${id}/notes`, {
      text: note
    })
  } catch (error) {
    throw Error(error)
  }
}
