<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card
      style="border-radius: 20px; border: 1px solid gray;"
    >
      <div
        class="my-4 ml-14 font-weight-bold"
        style="font-size: 28px;"
      >
        Sign up for Zestipy
      </div>

      <v-divider class="mb-6" />
      <v-card-text
        class="black--text"
      >
        <div class="mx-8">
          <v-alert
            v-if="errorBox"
            type="error"
            class="pa-2 mb-4 subtitle-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-form
            v-model="valid"
          >
            <!-- <div class="mb-1 subtitle-2">
              What's your email?
            </div> -->
            <v-text-field
              ref="email"
              v-model="email"
              placeholder="Email"
              :rules="[rules.required, rules.email]"
              outlined
              rounded
              dense
              class="mb-n1"
              height="38px"
              @focusout="checkAvailability('email', email)"
            >
              <template
                v-if="emailAvailable !== null"
                #append
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      v-if="emailAvailable === 'The email is available.'"
                      class="green--text"
                      style="cursor: pointer"
                      v-on="on"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      class="red--text"
                      style="cursor: pointer"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  {{ emailAvailable }}
                </v-tooltip>
              </template>
            </v-text-field>

            <v-text-field
              v-model="password"
              placeholder="Password"
              height="38px"
              outlined
              rounded
              dense
              :rules="[rules.required, rules.password]"
              class="mb-n1"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />

            <!-- <div class="mb-1 subtitle-2">
              What is your full name?
            </div> -->
            <v-text-field
              v-model="name"
              height="38px"
              placeholder="Full name"
              outlined
              rounded
              dense
              :rules="[rules.required, rules.name]"
              class="mb-n1"
            />

            <!-- <div class="mb-1">
              <span class="subtitle-2"> Create a unique profile name </span>
              <span class=""> (this is how other users will search you) </span>
            </div> -->
            <v-text-field
              v-model="username"
              height="38px"
              placeholder="Username"
              outlined
              rounded
              dense
              :rules="[rules.required, rules.username]"
              class="mb-n1"
              @focusout="checkAvailability('username', username)"
            >
              <template
                v-if="usernameAvailable !== null"
                #append
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      v-if="usernameAvailable === 'The username is available.'"
                      class="green--text"
                      style="cursor: pointer"
                      v-on="on"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      class="red--text"
                      style="cursor: pointer"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  {{ usernameAvailable }}
                </v-tooltip>
              </template>
            </v-text-field>
            <!-- <div class="mb-1 subtitle-2">
              Age
            </div> -->
            <v-text-field
              v-model="age"
              height="38px"
              placeholder="Age"
              outlined
              rounded
              dense
              class="mb-n2"
              :rules="[rules.required, rules.age]"
            />
          </v-form>

          <div
            class="subtitle-2"
            style="font-weight: 600;"
          >
            By creating an account, you agree to Zestipy’s

            <router-link
              to="/privacy-policy"
              target="_blank"
            >
              <span
                class="primary--text"
                style="cursor: pointer;"
              >
                Privacy Policy
              </span>
            </router-link>

            and
            <router-link
              to="/terms-of-use"
              target="_blank"
            >
              <span
                class="primary--text"
                style="cursor: pointer;"
              >
                Terms of Use
              </span>
            </router-link>.
          </div>
          <v-btn
            rounded
            height="38px"
            class="text-none black--text my-4"
            width="100%"
            elevation="0"
            color="accent"
            style="font-size: 16px;"
            :disabled="isDisabled"
            @click="handleEmailSignup"
          >
            Create Account
          </v-btn>

          <div
            class="subtitle-2 mb-2"
            style="font-weight: 600;"
          >
            Already have an account?
            <span
              class="primary--text pointer"
              @click="goToLogin"
            >
              Log in
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PasswordValidator from 'password-validator'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPassword: false,
      errorBox: false,
      errorMessage: '',
      valid: false,
      name: '',
      email: '',
      emailAvailable: null,
      username: '',
      usernameAvailable: null,
      password: '',
      age: '',
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        name: (value) => {
          const pattern = /^[a-zA-Z ]{2,30}$/
          return pattern.test(value) || 'Invalid name.'
        },
        username: (value) => {
          const pattern = /^[a-zA-Z0-9_]+$/
          return (
            pattern.test(value) ||
            'Must contain only letters, numbers or underscore.'
          )
        },
        password: (value) => {
          return (
            this.customPasswordValidator(value) ||
            'Must contain at least one uppercase, one lowercase, numbers, and 8 or more characters.'
          )
        },
        age: (value) => {
          if (value >= 12 && value <= 100) {
            return true
          } else {
            return 'Invalid age.'
          }
        }
      },
      strengthOptions: [
        {
          id: 0,
          value: 'Too weak',
          minDiversity: 0,
          minLength: 0
        },
        {
          id: 1,
          value: 'Weak',
          minDiversity: 2,
          minLength: 6
        },
        {
          id: 2,
          value: 'Medium',
          minDiversity: 3,
          minLength: 8
        },
        {
          id: 3,
          value: 'Strong',
          minDiversity: 4,
          minLength: 10
        }
      ]
    }
  },

  computed: {
    ...mapState(['isAuthLoading']),
    dialog: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },
    isDisabled () {
      if (!this.valid) {
        return true
      }
      if (this.emailAvailable !== 'The email is available.') {
        return true
      }

      if (this.usernameAvailable !== 'The username is available.') {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['signupWithEmail', 'userValidate']),
    async handleEmailSignup () {
      const payload = {
        name: this.name,
        email: this.email,
        username: this.username.toLowerCase(),
        password: this.password,
        dob: this.age,
        register_type: 'normal'
      }

      try {
        await this.signupWithEmail(payload)
        this.dialog = false
      } catch (error) {
        this.errorMessage = error
        this.errorBox = true
      }
    },
    customPasswordValidator (password) {
      // Create a schema
      const schema = new PasswordValidator()
      // Add properties to it
      schema
        .is()
        .min(8) // Minimum length 8
        .is()
        .max(100) // Maximum length 100
        .has()
        .uppercase() // Must have uppercase letters
        .has()
        .lowercase() // Must have lowercase letters
        .has()
        .digits(1) // Must have at least 2 digits
        .has()
        .not()
        .spaces() // Should not have spaces
        .is()
        .not()
        .oneOf(['Passw0rd', 'Password123']) // Blacklist these values
      return schema.validate(password)
    },
    goToLogin () {
      this.dialog = false
      this.$emit('openLogin')
    },
    async checkAvailability (type, value) {
      const options = {
        verify_field: type
      }

      if (type === 'username') {
        options.username = value.toLowerCase()
      } else {
        options.email = value.toLowerCase()
      }

      if (value.length === 0) {
        return ''
      }

      try {
        const result = await this.userValidate(options)
        if (type === 'username') {
          this.usernameAvailable = result.message
        } else {
          this.emailAvailable = result.message
        }
      } catch (error) {
        if (type === 'username') {
          this.usernameAvailable = error
        } else {
          this.emailAvailable = error
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  text-decoration: none;
}
.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
