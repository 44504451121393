<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="filteredItems"
      :search-input.sync="search"
      solo
      dense
      flat
      rounded
      outlined
      class="font-weight-bold hidden-xs-only"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      hide-details
      no-filter
      item-text="keyword"
      clearable
      hide-no-data
      @keypress.enter="handleEnter"
    >
      <template
        #item="{ item }"
      >
        <v-row
          no-gutters
        >
          <v-col v-if="item.type === 'DIVIDER'">
            <v-divider />
            <div class="">
              USERS
            </div>
          </v-col>
          <v-col
            v-else
            @click="handleClick(item)"
          >
            <v-list-item v-if="item.type === 'USER'">
              <v-list-item-avatar
                color="indigo"
                class="text-h5 font-weight-light white--text"
              >
                <v-img :src="item.avatar ? item.avatar : getAvatarPlaceHolder" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.username" />
                <v-list-item-subtitle v-text="item.name" />
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  v-if="!item.follow"
                  rounded
                  dense
                  small
                  class="elevation-0 text-none primary"
                >
                  Follow
                </v-btn>
                <v-btn
                  v-else
                  rounded
                  dense
                  small
                  class="elevation-0 text-none primary"
                >
                  Following
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-else
              class="py-n2"
            >
              <v-list-item-avatar>
                <!-- <div class="mx-auto">
                  {{ item.keyword.charAt(0) }}
                </div> -->
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="body-1"
                  v-text="item.keyword"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isLoading: false,
      model: null,
      search: null,
      items: [],
      tab: null,
      route: null
    }
  },
  computed: {
    filteredItems () {
      return [...this.searchListAutoComplete, ...this.searchListUsers]
    },
    searchListUsers () {
      return this.items.filter(item => item.type === 'USER')
    },
    searchListAutoComplete () {
      return this.items.filter(item => item.type === 'AUTOCOMPLETE')
    },
    getAvatarPlaceHolder () {
      return require('@/assets/profile_placeholder.png')
    }
  },
  watch: {
    model (val) {
      if (val != null) this.tab = 0
      else this.tab = null
    },
    search (value) {
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // lookup route and fetch accordingly
      this.explorerSuggest(this.search)
        .then(res => {
          this.items = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    ...mapActions([
      'explorerSuggest'
    ]),
    handleClick (item) {
      if (item.type === 'USER') {
        this.$router.push(`/user/${item.id}`)
      }
      if (item.type === 'AUTOCOMPLETE') {
        this.$router.push(`/search/${item.keyword}`)
      }
    },
    handleEnter (event) {
      this.$router.push(`/search/${event.target.value}`)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
