// eslint-disable-next-line camelcase
export function setUser (state, user = null) {
  if (user) {
    // eslint-disable-next-line camelcase
    const { id, name, email, username, avatar, access_token } = user
    state.user = { id, name, email, username, avatar }
    // set to local storage
    if (id) {
      window.localStorage.setItem('z_id', id)
    }
    // eslint-disable-next-line camelcase
    if (access_token) {
      window.localStorage.setItem('accessKey', access_token)
    }
  } else {
    state.user = {}
    state.user['id'] = window.localStorage.getItem('z_id')
  }
}

export function setAuthStatus (state, value) {
  state.isAuthenticated = value
}

export function setAuthLoading (state, value) {
  state.isAuthLoading = value
}

export function setCookbooks (state, value) {
  state.cookbooks = value
}

export function setCollaboratingCookbooks (state, value) {
  state.collaboratingCookbooks = value
}
export function setRecentlyViewed (state, value) {
  state.recentlyViewed = value
}

export function setFavouriteCookbooks (state, value) {
  state.favouriteCookbooks = value
}

function sortRecipes (a, b) {
  if (a.title < b.title) {
    return -1
  }
  if (a.title > b.title) {
    return 1
  }
  return 0
}

export function setFavouriteRecipes (state, { data, pageNumber }) {
  if (pageNumber === 1) {
    state.favouriteRecipes = data.sort((a, b) => sortRecipes(a, b))
  } else {
    const newList = [...state.favouriteRecipes, ...data].sort((a, b) => sortRecipes(a, b))
    state.favouriteRecipes = newList
  }
}
export function setSharedCookbooks (state, value) {
  state.sharedCookBooks = value
}

export function setSharedRecipes (state, { data, pageNumber }) {
  if (pageNumber === 1) {
    state.sharedRecipes = data.sort((a, b) => sortRecipes(a, b))
  } else {
    const newList = [...state.sharedRecipes, ...data].sort((a, b) => sortRecipes(a, b))
    state.sharedRecipes = newList
  }
}

export function setHomeFeed (state, value) {
  if (state.feed.length === 0) {
    state.feed = value
  } else {
    const newFeed = [...state.feed, ...value]
    state.feed = newFeed
  }
}

export function setMyRecipes (state, { data, pageNumber }) {
  if (pageNumber === 1) {
    state.myRecipes = data.sort((a, b) => sortRecipes(a, b))
  } else {
    const newList = [...state.myRecipes, ...data].sort((a, b) => sortRecipes(a, b))
    state.myRecipes = newList
  }
}

export function setSuggestedRecipes (state, value) {
  state.suggestedRecipes = value
}

export function updateApiStatus (state, { type, status }) {
  state.apiStatus[type] = status
}

export function setMyProfile (state, value) {
  state.profile = value
}

export function setMyFollowers (state, value) {
  state.myProfileFollowers = value
}

export function setMyNotification (state, value) {
  state.notifications = value
}

export function updateMeta (state, { type, meta }) {
  state.meta[type] = meta
}
