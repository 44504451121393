<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card style="border-radius: 20px; border: 1px solid gray;">
      <v-card-title class="text-h5 lighten-2 pr-2">
        Add to Cookbook
      </v-card-title>
      <v-card-text>
        <v-row
          no-gutters
          class="mt-2"
        >
          <v-col
            v-for="(cookbook, i) in cookbooks"
            :key="i"
            cols="12"
            class="d-flex my-2 align-center"
          >
            <v-avatar
              color="primary"
              class="mr-2"
            >
              <v-img :src="cookbook.image ? cookbook.image : getPlaceHolderImage" />
            </v-avatar>

            <div
              style="font-size: 20px; color: #2b2b2b;"
              class="mx-2"
            >
              {{ cookbook.name }}
            </div>

            <v-spacer />
            <v-btn
              v-if="!cookbook.added"
              class="primary white--text text-none"
              rounded
              depressed
              width="100px"
              @click="handleAdd(cookbook.id)"
            >
              <span
                style="font-size: 20px;"
              >
                Add
              </span>
            </v-btn>
            <v-btn
              v-else
              class="primary white--text text-none"
              rounded
              depressed
              width="100px"
              @click="handleRemove(cookbook.id)"
            >
              <span
                style="font-size: 18px;"
              >
                Remove
              </span>
            </v-btn>
          </v-col>

          <v-col
            v-if="cookbooks.length === 0"
            cols="12"
            class="mt-2"
          >
            No cookbooks found
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="errorPopup"
      width="300"
      height="100"
    >
      <v-card
        class="pa-4 red--text"
      >
        {{ errorMessage }}
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { EventBus } from '@/main'
export default {
  data () {
    return {
      dialog: false,
      resource: null,
      buttons: null,
      errorPopup: false,
      errorMessage: null,
      cookbooks: []
    }
  },
  computed: {
    ...mapGetters(['getMyCookbooks']),
    ...mapState(['user', 'collaboratingCookbooks']),
    getPlaceHolderImage () {
      return require('@/assets/recipe_placeholder.png')
    }
  },
  async created () {
    EventBus.$on('addToCookbook', (data) => {
      this.resource = data
      const ownedCookbooks = this.getMyCookbooks.reduce((acc, cookbook) => {
        if (cookbook.author.id === this.user.id) {
          acc.push({
            ...cookbook,
            added: false
          })
        }
        return acc
      }, [])

      this.cookbooks = [...ownedCookbooks, ...this.collaboratingCookbooks]

      this.dialog = true
    })
  },
  methods: {
    ...mapActions(['addRecipeToCookbook', 'removeFromCookbook']),
    async handleAdd (cookbookId) {
      const recipeIndex = this.cookbooks.findIndex(cookbook => cookbook.id === cookbookId)
      try {
        await this.addRecipeToCookbook({ recipeId: this.resource.recipeId, cookbookId })
        this.cookbooks[recipeIndex].added = true
      } catch (error) {
        this.cookbooks[recipeIndex].added = true
      }
    },
    async handleRemove (cookbookId) {
      const recipeIndex = this.cookbooks.findIndex(cookbook => cookbook.id === cookbookId)
      try {
        await this.removeFromCookbook({ recipeId: this.resource.recipeId, cookbookId })
        this.cookbooks[recipeIndex].added = false
      } catch (error) {
        this.cookbooks[recipeIndex].added = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
