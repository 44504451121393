<template>
  <v-row dense>
    <v-col
      v-if="getNotifications.length === 0"
      cols="12"
    >
      You are up to date.
    </v-col>
    <v-col
      v-else
      cols="12"
    >
      <v-card
        outlined
      >
        <template v-for="(item,i) in getNotifications">
          <router-link
            :key="i"
            :to="getUrl(item.resource)"
            class="black--text body-2"
            style="text-decoration:none"
          >
            <div
              :id="`header-${i}`"
              class="d-flex pa-2 justify-center pointer"
              :class="item.user_action ? 'read' : 'unread'"
              @click="handleClick(item)"
            >
              <div>
                <v-avatar size="40">
                  <v-img
                    :src="item.resource.avatar"
                    :lazy-src="getPlaceHolder"
                  />
                </v-avatar>
                <span class="font-weight-medium ml-1">
                  {{ item.resource.username }}
                </span> {{ getEventText(item.event_type) }}
              </div>
              <div class="ml-auto text--grey caption">
                {{ item.created_at }}
              </div>
            </div>
          </router-link>
          <v-divider :key="`hr-${i}`" />
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BasePage2 from '@/base/BasePage2.vue'
export default {
  components: { BasePage2 },
  computed: {
    ...mapState(['apiStatus', 'notifications']),
    status () {
      return this.apiStatus.myNotifications
    },
    getNotifications () {
      return this.notifications.data || []
    },
    getPlaceHolder () {
      return require('@/assets/profile_placeholder.png')
    }
  },
  created () {
    this.getMyNotification()
  },
  methods: {
    ...mapActions(['getMyNotification', 'readNotification']),
    getEventText (type) {
      if (type === 'cookbook_shared') {
        return 'shared a cookbook with you.'
      } else if (type === 'recipe_shared') {
        return 'shared a recipe with you.'
      } else if (type === 'follow_user') {
        return 'followed you.'
      } else {
        return type
      }
    },
    getUrl (resource) {
      if (resource['recipe_id']) {
        return `/recipe/${resource['recipe_id']}`
      } else if (resource['cookbook_id']) {
        return `/cookbook/${resource['cookbook_id']}`
      } else {
        return `/user/${resource['id']}`
      }
    },
    handleClick (item) {
      if (!item.user_action) {
        this.readNotification(item.uuid)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.unread {
  background-color: #d0d0d13f;
}
.read {
  background-color:white;
}

.pointer:hover {
  cursor: pointer;
  background-color: #415f7c3f;
}
</style>
