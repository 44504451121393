<template>
  <main class="main">
    <section
      class="section section-1 primary"
      style="position: relative"
    >
      <video
        :src="getWelcomeVideo"
        loop
        autoplay
        muted
        style="
          position: absolute;
          top: -10vh;
          width: 100vw;
          height: 100vh;
          object-fit: cover;
        "
      />

      <nav
        class="d-flex align-center px-8"
        style="
          height: 60px;
        "
      >
        <div
          style="width: 240px;  z-index: 11;"
        >
          <router-link
            to="/"
            style="text-decoration: none"
            class="d-flex align-center"
          >
            <div style="height: 42px; width: 42px">
              <v-img :src="getZestipyImage" />
            </div>
            <div
              v-if="!$vuetify.breakpoint.xsOnly"
              class="ml-2 white--text"
              style="font-family: Nyata FTR; font-size: 32px"
            >
              Zestipy
            </div>
          </router-link>
        </div>

        <div
          class="ml-auto d-flex align-center"
          style="z-index: 1"
        >
          <router-link
            to="/about"
            class="mr-4 white--text underline_hover font-weight-bold"
          >
            About
          </router-link>

          <v-btn
            rounded

            class="mr-2 white--text text-none font-weight-bold"
            color="black"
            @click="$emit('login')"
          >
            Log In
          </v-btn>

          <v-btn
            rounded
            class="text-none white--text font-weight-bold"
            style="background-color: #c4c4c4"
            @click="$emit('signUp')"
          >
            Sign Up
          </v-btn>
        </div>
      </nav>
      <v-row
        no-gutters
        style="min-height: 80vh; position: relative"
      >
        <v-col
          class="banner-title white--text d-flex justify-center align-center"
          cols="12"
        >
          <div
            class="text-center pa-4"
            style="margin-bottom: 140px; line-height: 1.25; max-width: 992px;"
          >
            Creating, sharing, and exploring recipes made easier!
          </div>
        </v-col>

        <div
          class="accent d-flex justify-center align-center bounce"
          style="
            position: absolute;
            bottom: 0;
            left: 48%;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          "
        >
          <div>
            <v-icon> mdi-chevron-down</v-icon>
          </div>
        </div>
      </v-row>
      <div class="next-slide secondary d-flex align-center justify-center">
        <div class="accent--text header-title">
          Here's how it works <v-icon color="accent">
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </section>

    <section class="section section-2 secondary">
      <v-row
        no-gutters
        style="height: 100%"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-img
            :src="getCreateImg"
            height="100%"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"

          class="text-center pa-8 d-flex flex-column align-center justify-center"
        >
          <div class="banner-title white--text">
            Create Recipes and Cookbooks
          </div>
          <div class="banner-subtitle accent--text">
            Add your recipes into the platforms and save them in cookbooks. Set
            them to private or public if you want other users to see them.
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="section section-3 accent">
      <v-row
        no-gutters
        style="height: 100%"
      >
        <v-col
          cols="12"
          class="d-flex d-sm-none"
        >
          <v-img
            :src="getShareImg"
            height="100%"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-center pa-8 d-flex flex-column align-center justify-center"
        >
          <div class="banner-title white--text">
            Share with others
          </div>
          <div class="banner-subtitle primary--text">
            Easily share with others users inside or outside the app. Any shared
            recipe is saved to an account for future use.
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="hidden-xs-only"
        >
          <v-img
            :src="getShareImg"
            height="100%"
          />
        </v-col>
      </v-row>
    </section>

    <section
      class="section section-4 primary"
      style="position: relative"
    >
      <v-row
        no-gutters
        style="height: 95%"
      >
        <v-col
          cols="12"
          sm="6"
          class="grey"
          height="100%"
        >
          <v-img
            :src="getExploreImg"
            height="100%"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-center pa-8 d-flex flex-column align-center justify-center"
        >
          <div class="banner-title white--text">
            Explore
          </div>
          <div class="banner-subtitle accent--text">
            Search for all public recipes created by other users.
          </div>
        </v-col>
      </v-row>

      <app-footer />
    </section>
  </main>
</template>

<script>
import AppFooter from '@/layouts/AppFooter.vue'
export default {
  name: 'PublicHomeView',
  components: {
    AppFooter
  },
  computed: {
    getZestipyImage () {
      return require('@/assets/logo_white_background.png')
    },
    getWelcomeVideo () {
      return require('@/assets/welcome.mp4')
    },
    getCreateImg () {
      return require('@/assets/create-recipe.png')
    },
    getShareImg () {
      return require('@/assets/sharing-recipe.png')
    },
    getExploreImg () {
      return require('@/assets/explore-recipe.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  font-family: 'Nyata FTR';
  font-size: 30px;
  z-index: 1;
}
.header-title {
  font-size: 24px;
  font-family: 'Nyata FTR';
}

.banner-subtitle {
  font-size: 28px;
  font-family: 'Nyata FTR';
  letter-spacing: -0.02em;
}

main,
section {
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  font-family: 'Lato', sans-serif;
}

.main {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.main::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.section {
  box-sizing: border-box;
  scroll-snap-align: center;
}

.header-top {
  height: 10vh;
  padding: 0 10px;
  top: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.next-slide {
  height: 13vh;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  // bottom: 0;
  // position: absolute;
}
.underline_hover {
  text-decoration: none;
}
.underline_hover:hover {
  text-decoration: underline;
  color: red;
}

.banner-title {
  font-size: 72px;
  font-weight: 800;
  font-family: 'Lato';
  margin-bottom: 16px;
  line-height: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .banner-title {
    font-size: 48px;
  }
}
/* Small devices (600px and up) */
@media only screen and (min-width: 600px) {
  .banner-title {
    font-size: 58px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-title {
    font-size: 64px;
  }
}
/* Large devices (992px and up) */
@media only screen and (min-width: 992px) {
  .banner-title {
    font-size: 72px;
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>
