import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import { mapGetters } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'

Vue.use(infiniteScroll)

Vue.prototype.$axios = axios

Vue.mixin({
  computed: {
    ...mapGetters({
      $user: 'getUser'
    })
  }
})

export const EventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
