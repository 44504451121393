import { API_INITIAL } from '@/constants/api'

export default {
  // user
  user: null,
  isAuthenticated: false,
  isAuthLoading: null,
  // my-profile
  profile: null,
  myProfileFollowers: [],
  myProfileFollowing: [],
  notifications: [],
  // main-feed
  feed: [],
  // cookbooks
  cookbooks: null,
  collaboratingCookbooks: [],
  // explorer
  suggestedRecipes: [],
  recentlyViewed: [],
  // favourites
  favouriteRecipes: [],

  // shared-with-me
  sharedRecipes: [],
  sharedCookBooks: [],
  // my-kitchen
  myRecipes: [],
  // api
  apiStatus: {
    feed: { isLoading: true, isloaded: false },
    cookbooks: { isLoading: true, isloaded: false },
    suggestedRecipes: { isLoading: true, isloaded: false },
    recentlyViewed: { isLoading: true, isloaded: false },
    favouriteRecipes: { isLoading: true, isloaded: false },
    sharedRecipes: { isLoading: true, isloaded: false },
    sharedCookBooks: { isLoading: true, isloaded: false },
    myRecipes: { isLoading: true, isloaded: false },
    myProfile: { isLoading: true, isloaded: false },
    myProfileFollowing: { isLoading: true, isloaded: false },
    myProfileFollowers: { isLoading: true, isloaded: false },
    myNotifications: API_INITIAL
  },
  meta: {
    feed: null,
    suggestedRecipes: null,
    recentlyViewed: null,
    myRecipes: null,
    favouriteRecipes: null,
    sharedWithMe: null
  }
}
