<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card style="border-radius: 20px; border: 1px solid gray;">
      <v-card-title class="text-h5 lighten-2 pr-2">
        Report
      </v-card-title>

      <v-card-text>
        <v-row
          v-if="!selectedReportType && !showConfirmation"
          class="mt-2"
          no-gutters
        >
          <v-col
            cols="12"
            class="text-center mb-4"
          >
            Why are you reporting this
            <span v-if="resource && resource.type">
              {{ resource.type }}
            </span>
            ?
          </v-col>
          <v-col
            v-for="(type, j) in types"
            :key="j"
            cols="12"
          >
            <v-btn
              class="primary mb-2"
              rounded
              width="100%"
              depressed
              @click="handleClick(type)"
            >
              {{ type.title }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-else-if="selectedReportType && !showConfirmation"
          class="mt-2"
          no-gutters
        >
          <v-col cols="12">
            <div class="mb-2">
              Please describe and confirm you are reporting this {{ resource.type }} as {{ selectedReportType.title }}
            </div>

            <v-textarea
              v-model="reportDescription"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="primary white--text"
              rounded
              depressed
              @click="handleReport"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-else
          class="mt-4"
        >
          <v-col>
            Thanks your report has been submitted successfully.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/main'
export default {
  data () {
    return {
      dialog: false,
      resource: null,
      selectedReportType: null,
      reportDescription: '',
      showConfirmation: null,
      types: [
        {
          id: 1,
          title: 'Abuse'
        },
        {
          id: 2,
          title: 'Adult Content'
        },
        {
          id: 3,
          title: 'Hateful Content'
        }, {
          id: 4,
          title: 'Spam'
        },
        {
          id: 5,
          title: 'Violates Intellectual Property'
        },
        {
          id: 6,
          title: 'Other'
        }
      ]
    }
  },
  async created () {
    EventBus.$on('report', (data) => {
      this.resource = data
      this.dialog = true
    })
  },
  methods: {
    ...mapActions(['reportCookbook', 'reportRecipe', 'reportReview', 'reportUser']),
    async handleReport () {
      try {
        const payload = {
          id: null,
          type: this.selectedReportType.title,
          description: this.reportDescription
        }

        if (this.resource.type === 'recipe') {
          payload['id'] = this.resource.recipeId
          await this.reportRecipe(payload)
        } else if (this.resource.type === 'cookbook') {
          payload['id'] = this.resource.cookbookId
          await this.reportCookbook(payload)
        } else if (this.resource.type === 'user') {
          payload['id'] = this.resource.userId
          await this.reportUser(payload)
        } else {
        // this.reportReview()
        }

        this.showConfirmation = true
      } catch (error) {
        this.showConfirmation = false
        throw Error(error)
      }
    },
    handleClick (type) {
      this.selectedReportType = type
    },
    close () {
      this.dialog = false
      this.resource = null
      this.selectedReportType = null
      this.reportDescription = ''
      this.showConfirmation = null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
