<template>
  <v-dialog
    id="dialog"
    v-model="dialog"
    width="500"
    scrollable
  >
    <v-card
      id="card"
      style="border-radius: 20px; border: 1px solid gray;"
    >
      <div
        class="my-4 ml-14 font-weight-bold"
        style="font-size: 28px;"
      >
        Log in to Zestipy
      </div>
      <v-divider class="mb-6" />
      <div class="mx-14">
        <v-alert
          v-if="errorBox"
          type="error"
          class="pa-2 mb-4 subtitle-2"
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          v-model="email"
          placeholder="Email"
          outlined
          rounded
          dense
          hide-details
          height="38px"
          class="mb-4"
        />

        <v-text-field
          v-model="password"
          placeholder="Password"
          outlined
          rounded
          dense
          hide-details
          width="200px"
          class="mb-4"
          height="38px"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          @keypress.enter="handleLogin({email, password})"
        />

        <div
          class="text-primary link body-2 mb-2"
          style="font-weight: 600; text-decoration: underline;"
          @click="forgetPasswordDialog = true"
        >
          <span
            class="primary--text"
            style="cursor: pointer;"
          >
            Forgot password?
          </span>
        </div>

        <div
          class="subtitle-2 mb-4"
          style="font-weight: 600;"
        >
          By using Zestipy, you agree to the <router-link
            to="/privacy-policy"
            target="_blank"
          >
            <span
              class="primary--text"
              style="cursor: pointer;"
            >
              Privacy Policy
            </span>
          </router-link>

          and
          <router-link
            to="/terms-of-use"
            target="_blank"
          >
            <span
              class="primary--text"
              style="cursor: pointer;"
            >
              Terms of Use
            </span>
          </router-link>.
        </div>
        <v-btn
          rounded
          color="accent"
          class="text-none black--text font-weight-bold"
          style="font-size: 16px;"
          width="100%"
          elevation="0"
          height="38px"
          :loading="isAuthLoading"
          @click="handleLogin({email, password})"
        >
          Log in
        </v-btn>

        <div
          class="subtitle-2 my-4"
          style="font-weight: 600;"
        >
          Need to create an account? <span
            class="primary--text"
            style="text-decoration: underline; cursor: pointer;"
            @click="$emit('openSignup')"
          >Sign up</span>!
        </div>

        <v-dialog
          v-model="forgetPasswordDialog"
          width="500"
        >
          <v-card
            width="500"
            height="400"
            style="border-radius: 20px; border: 1px solid gray;"
          >
            <div
              style="font-size: 28px;"
              class="font-weight-bold my-4 pl-14"
            >
              Forget Password?
            </div>
            <v-divider />
            <div
              class="d-flex flex-column justify-center align-center"
              style="width: 100%; height: 100%;"
            >
              <div
                class="mb-4 mt-n6"
              >
                <div class="subtitle-2 mb-4 text-center">
                  {{ resetEmailResponse }}
                </div>

                <v-text-field
                  v-model="resetEmail"
                  placeholder="Email"
                  outlined
                  rounded
                  dense
                  hide-details
                  height="38px"
                  class="mb-4"
                  style="width: 400px;"
                />
              </div>
              <div class="mb-6">
                <v-btn
                  rounded
                  color="accent"
                  class="text-none black--text font-weight-bold"
                  style="font-size: 16px;"
                  elevation="0"
                  height="38px"
                  @click="handleForgetPassword"
                >
                  Send a password reset email
                </v-btn>
              </div>
              <v-btn
                text
                class="text-none black--text font-weight-bold"
                @click="forgetPasswordDialog = false"
              >
                Back
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
        <!-- <div class="text-center mb-4">
          or
        </div> -->
        <!-- <v-btn
          rounded
          class="text-none black--text mb-4"
          width="100%"
          elevation="0"
          @click="handleGoogleLogin"
        >
          Connect with Google
        </v-btn> -->

        <!-- <v-btn
          rounded
          color="#4267B2"
          class="text-none white--text mb-8"
          width="100%"
          elevation="0"
          @click="handleFbLogin"
        >
          Connect with Facebook
        </v-btn> -->
        <!-- <div class="d-flex justify-center mt-4 mb-4">
          <router-link
            to="/privacy-policy"
            class="mx-2"
            @click="handleClose"
          >
            Privacy
          </router-link>

          <router-link
            to="/terms-of-use"
            class="mx-2"
            @click="handleClose"
          >
            Terms of Use
          </router-link>
        </div> -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      email: '',
      password: '',
      errorBox: false,
      errorMessage: '',
      base: process.env.VUE_APP_BASE_URL,
      showPassword: false,
      forgetPasswordDialog: false,
      resetEmail: '',
      resetEmailResponse: null
    }
  },

  computed: {
    ...mapState(['isAuthLoading']),
    dialog: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  methods: {
    ...mapActions([
      'login', 'facebookLogin', 'passwordResetLink'
    ]),
    async handleLogin ({ email, password }) {
      try {
        await this.login({ credentials: { email, password }, tokenExists: false })
        this.$emit('close')
      } catch (error) {
        this.showErrorBox(error)
        this.email = ''
        this.password = ''
      }
    },
    handleClose () {
      this.$emit('close')
      this.error = null
      this.email = ''
      this.password = ''
      this.errorBox = false
      this.errorMessage = ''
    },
    handleForgetPassword () {
      this.passwordResetLink(this.resetEmail)
        .then((response) => {
          this.resetEmailResponse = response
        }).catch((error) => {
          this.resetEmailResponse = error
        })
    },
    showErrorBox (msg) {
      this.errorMessage = msg
      this.errorBox = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
