import axios, { CancelToken } from 'axios'
import { el } from 'date-fns/locale'

const pendingRequests = {}
// use cancel token to cancel duplicate request
const addPendingRequest = (config) => {
  const url = [config.method, config.url, JSON.stringify(config.data)].join('&')
  config.cancelToken = config.cancelToken ||
    new CancelToken((cancel) => {
      if (!pendingRequests[url]) {
        pendingRequests[url] = cancel
      }
    })
}

const removePendingRequest = (config) => {
  const url = [config.method, config.url, JSON.stringify(config.data)].join('&')
  if (pendingRequests[url]) {
    pendingRequests[url]('Cancel duplicate request')
    delete pendingRequests[url]
  }
}

const config = {
  baseURL: process.env.VUE_APP_BASE_URL
}

const instance = axios.create(config)

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (window.localStorage.getItem('accessKey')) {
      config.headers.common['Authorization'] =
        'Bearer ' + window.localStorage.getItem('accessKey')
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// use interceptors to add/remove request
instance.interceptors.request.use(
  (config) => {
    // check if request is already pending
    // if yes, cancel the previous request
    // if no, add request to pending list
    if (pendingRequests[config.url]) {
      // cancel previous request
      removePendingRequest(config)
    } else {
      // add request to pending list
      addPendingRequest(config)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response)
  }
)

export default instance
