<template>
  <div />
</template>

<script>
import Shepherd from 'shepherd.js'

export default {
  name: 'TourView',
  data () {
    return {

    }
  },
  mounted () {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        classes: 'shepherd-dialog-box'
      },
      useModalOverlay: true
    })

    tour.addStep({
      id: 'step1',
      title: 'Welcome tour',
      text: 'Explore the features of Zestipy. Let\'s get started!',
      classes: 'shephered-dialog-box',
      buttons: [
        {
          action: tour.next,
          text: 'Next'
        }
      ]
    })

    tour.addStep({
      id: 'step2',
      title: 'Search for Recipes',
      text: 'Use the search bar to find delicious recipes based on your liking.',
      attachTo: { element: '#search', on: 'bottom' },
      buttons: [
        {
          action: tour.back,
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action: tour.next,
          text: 'Next'
        }
      ],
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [30, 20]
            }
          }
        ]
      }
    })

    tour.addStep({
      id: 'step6',
      title: 'Explore Recipes',
      text: 'Discover a collection of recipes shared by others in the Explore tab. Find new inspiration for your next meal!',
      attachTo: { element: '#explorer', on: 'right' },
      buttons: [
        {
          action: tour.back,
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action: tour.next,
          text: 'Next'
        }
      ]
    })

    tour.addStep({
      id: 'step4',
      title: 'Create your Recipes',
      text: 'Share your own culinary creations by clicking on \'Create Recipe\' to add your recipe to the platform.',
      attachTo: { element: '#create-recipe', on: 'right' },
      buttons: [
        {
          action: tour.back,
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action: tour.next,
          text: 'Next'
        }
      ]
    })

    tour.addStep({
      id: 'step5',
      title: 'Add to Favorite',
      text: 'Save your favorite recipes to easily access them later in the "Favorites" tab.',
      attachTo: { element: '#favorites', on: 'right' },
      buttons: [
        {
          action: tour.back,
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action: tour.complete,
          text: 'Finish'
        }
      ]
    })

    tour.on('complete', () => {
      console.log('Tour complete!')
    })

    tour.start()
  },
  methods: {
    async completeTour () {
      // Call your asynchronous method here
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log('Async method!')
          resolve()
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss">
.shepherd-dialog-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.shepherd-arrow {
  outline-color: black;
}

.shepherd-button  {
  border-radius: 20px;
  // background-color: lightcyan;
}

.shepherd-button-secondary {
  background-color: #ffffff;
  color: #c7c7c7;
}
.shepherd-modal-is-visible{
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.5);
}

.shepherd-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
}
 </style>
