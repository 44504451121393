<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card style="border-radius: 20px; border: 1px solid gray;">
      <v-card-title class="text-h5 lighten-2 pr-2">
        Share
      </v-card-title>

      <v-card-text>
        <v-row
          no-gutters
          class="mt-2"
        >
          <v-col
            v-for="(follower, j) in followers"
            :key="j"
            cols="12"
            class="d-flex my-2"
          >
            <v-avatar
              color="primary"
              class="mr-2"
            >
              <v-img :src="follower.avatar ? follower.avatar : getUserPlaceHolderImage" />
            </v-avatar>
            <div style="color: #2b2b2b;">
              <div
                style="font-size: 20px;"
              >
                {{ follower.username }}
              </div>
              <div>
                {{ follower.name }}
              </div>
            </div>
            <v-spacer />
            <v-btn
              v-if="!follower.recipes || !follower.recipes[resource.recipeId]"
              class="primary white--text text-none"
              rounded
              depressed
              width="100px"
              style="font-size: 20px;"
              @click="handleShare(follower.id)"
            >
              Share
            </v-btn>
            <v-btn
              v-else
              class="success white--text text-none"
              rounded
              depressed
              width="100px"
              style="font-size: 20px;"
            >
              Sent
            </v-btn>
            <v-col
              v-if="myProfileFollowers.length === 0"
              cols="12"
              class="mt-2"
            >
              You've no followers
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/main'
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      resource: null,
      loader: {},
      followers: []
    }
  },
  computed: {
    ...mapState(['myProfileFollowers', 'profile']),
    getUserPlaceHolderImage () {
      return require('@/assets/profile_placeholder.png')
    }
  },
  async created () {
    EventBus.$on('share', async (data) => {
      this.resource = data
      this.followers = this.myProfileFollowers.map(follower => {
        return {
          ...follower,
          recipes: null,
          cookbooks: null
        }
      })
      this.dialog = true
    })
    await this.getMyProfile()
    await this.getFollowers(this.profile.id)
  },
  methods: {
    ...mapActions(['shareRecipe', 'shareCookbook', 'getMyProfile', 'getFollowers']),
    updateSharedState (followerId, resourceId, resourceType) {
      const follower = this.followers.find(follower => follower.id === followerId)
      if (follower) {
        if (!follower[resourceType]) {
          this.$set(follower, resourceType, {})
        }
        this.$set(follower[resourceType], resourceId, true)
      }
    },
    async handleShare (followerId) {
      if (this.resource.type === 'recipe') {
        try {
          await this.shareRecipe({ recipeId: this.resource.recipeId, followerId })
          this.updateSharedState(followerId, this.resource.recipeId, 'recipes')
        } catch (error) {
          if (error === 422) {
            this.updateSharedState(followerId, this.resource.recipeId, 'recipes')
          }
        }
      } else {
        try {
          await this.shareCookbook({ cookbookId: this.resource.cookbookId, followerId })
          this.updateSharedState(followerId, this.resource.cookbookId, 'cookbooks')
        } catch (error) {
          if (error === 422) {
            this.updateSharedState(followerId, this.resource.cookbookId, 'cookbooks')
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
